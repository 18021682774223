import React from "react"
import NavBar from "../Components/Navbar";
import Footer from '../Components/Footer';
import PageContent from "../Components/PageContent";
import experienceImg from '../assets/experienceImg.jpg';
import SkillsSection from "../Components/Experience/SkillsSection";
import Education from "../Components/Experience/Education";
import Work from "../Components/Experience/Work";

function Experience () {
  return (
    <div>
      <NavBar/>
      <PageContent img ={experienceImg} text1="Experience" classLabel= "ExperienceLabel" />
      <Work/>
      <Education/>
      <SkillsSection/>
      
      <Footer/>
    </div>
  )
};

export default Experience;
