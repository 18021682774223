import React from "react"
import './PageContentStyles.css';

function PageContent (props) {
  return (
       <div className="page-mask">
      <img className="page-img" src={props.img}/>
      <div className="content">
        <h1 className={props.classLabel}>{props.text1}</h1>
<p className="PageDescription">{props.text2}</p>
      </div>
    </div>
  )
};

export default PageContent;
