import React from "react";
import { NavLink } from "react-router-dom";
import "./Education.css";

function Education() {
  return (
    <div>
      <h1 className="experience-heading">Education</h1>
      <div className="experience-container">
        
        <div className="experience-card">
          <h2 className="experience-title">Computer Engineer Graduate @ TMU</h2>
          <div className="pro-details">
            <p>
              • Major in Computer Engineering (Software Engineering specialization)
            </p>
            <p>• CGPA: 3.85 / 4.33</p>
            <div className="details">
            <NavLink
                to="https://learner.mycreds.ca/sharelink/43f9b17c-b5c3-4366-80b5-3f5dcb8d31a9/27de140b-feba-4eba-baf0-79342ee8c5db"
                className="details-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credentials
              </NavLink>
            </div>
          </div>
        </div>

        <div className="experience-card">
          <h2 className="experience-title">AWS Certified Cloud Practitioner</h2>
          <div className="pro-details">
            <p>
              • Demonstrated understanding of AWS Cloud concepts, core AWS
              services, and basic AWS architecture best practices.
            </p>
            <p>
              • Knowledgeable in AWS security, compliance, and shared
              responsibility model.
            </p>
            <div className="details">
            <NavLink
                to="https://www.credly.com/badges/f6e27656-3725-4697-b708-3816558c8366/linked_in_profile"
                className="details-btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Credentials
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;