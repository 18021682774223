import React from "react"

function Skill ({src,skill}) {
  return (
<span>
    <img src={src} alt="Checkmark Dark"></img>
    <p >{skill}</p>
</span>
  )
};

export default Skill;