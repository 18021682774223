import React from "react";
import NavBar from "../Components/Navbar";
import HomeImg  from "../Components/HomePage/HomeImg";
import Footer from "../Components/Footer";
import SkillsSection from "../Components/Experience/SkillsSection";
import AboutMe from "../Components/HomePage/AboutMe";

function Home() {
  return (
    <div>
      <NavBar />
      <HomeImg/>
      <AboutMe/>
<Footer/>
    </div>
  );
}

export default Home;
