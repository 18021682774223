import React from "react"
import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Form from "../Components/Contact/Form";
import email3 from '../assets/email3.jpg';
import PageContent from "../Components/PageContent";
function Contact () {
  return (
    <div>
     <NavBar/>
     <PageContent img ={email3} text1="Let's chat!!!" classLabel= "ContactLabel" />
     {/* <ContactContent/> */}
<Form/>
     <Footer/>


    </div>
  )
};

export default Contact;
