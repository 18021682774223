import React from "react";
import "./FooterStyles.css";

import { FaHome, FaPhone, FaMailBulk, FaLinkedin, FaGithub } from "react-icons/fa";

function Footer() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">
          <div className="location">
            <FaHome size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p> Toronto</p>
            </div>
          </div>
          <div className="phone">
            <FaPhone size={20} style={{ color: "#fff", marginRight: "2rem" }} />
            <div>
              <p> 647 - 702 - 2728</p>
            </div>
          </div>
          <div className="email">
            <FaMailBulk
              size={20}
              style={{ color: "#fff", marginRight: "2rem" }}
            />
            <div>
              <p> akashsivapalan@gmail.com</p>
            </div>
          </div>
</div>
          <div className="right">
         
          <div className="social">
            <FaLinkedin
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
              onClick={() =>
                openInNewTab(
                  "https://www.linkedin.com/in/akash-sivapalan-309a85186/"
                )
              }
            />

<FaGithub
              size={30}
              style={{ color: "#fff", marginRight: "1rem" }}
              onClick={() => openInNewTab("https://github.com/AkashSivapalan")}
            />
          </div>
         </div>
      </div>
    </div>
  );
}

export default Footer;
