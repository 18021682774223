import './App.css';
import React from 'react';
import "./index.css";
import Home from './routes/home'
import Projects from './routes/project';
import Contact from './routes/contact';
import Experience from './routes/Experience';
import {Routes,Route} from 'react-router-dom';
function App() {
  return (
 <>

    <Routes>
      <Route path = "/" element={<Home/>}/>
      <Route path = "projects" element={<Projects/>}/> 
      <Route path = "contact" element={<Contact/>}/> 
      <Route path = "experience" element={<Experience/>}/> 
    </Routes>
 </>
 
  );
}

export default App;
