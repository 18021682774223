import React from "react";
import "./HomeImg.css";
import homeGif from "../../assets/homeGif.gif";
import AkashPic from "../../assets/AkashProfile.jpg";
import CV from "../../assets/AkashSivapalanResume.pdf";

function HomeImg() {
  return (
    <div className="homeImg">
      <div className="mask">
        <img className="into-img" src={homeGif} />
        <div className="content">
          <img className="profile-img" src={AkashPic} alt="Akash Profile" />
          <h1>Hi, my name is Akash. Welcome to my Portfolio!</h1>
          <div>
            <a download="" href={CV} className="btn btn-light">
              Download CV
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeImg;
