import React, { useState } from "react";
import "./NavbarStyles.css";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

function NavBar() {
  const [menuClick, setMenuClick] = useState(false);
  const handleClick = () => setMenuClick(!menuClick);
  const [colour, setColour] = useState(false);

  const changeColour = () => {
    if (window.scrollY >= 100) {
      setColour(true);
    } else {
      setColour(false);
    }
  };
  window.addEventListener("scroll", changeColour);
  return (
    <div className={colour ? "header header-bg" : "header"}>
      <Link to="/">
        <h1>Akash's Portfolio</h1>
      </Link>
      <ul className={menuClick ? "nav-menu active" : "nav-menu"}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/projects">Projects</Link>
        </li>
        <li>
          <Link to="/experience">Experience</Link>
        </li>        
        <li>
          <Link to="/contact">Contact</Link>
        </li>

      </ul>
      <div className="hamburger" onClick={handleClick}>
        {menuClick ? (
          <FaTimes size={20} style={{ color: "#fff" }} />
        ) : (
          <FaBars size={20} style={{ color: "#fff" }} />
        )}
      </div>
    </div>
  );
}

export default NavBar;
