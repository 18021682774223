import React from "react"
import './AboutMe.css';

function AboutMe () {
  return (
    <div className="aboutMe-content">
      <h1 className="headerLabel">About Me</h1>
      <p className="description">I'm a Computer Engineer graduate from TMU (formerly Ryerson), specializing in software engineering. From small-scale web applications to large-scale API testing, I have gained experience in a diverse range of projects.</p>
   <p className="description">In my free time, I enjoy honing my coding skills through coding challenges/side projects and exploring new programming frameworks to stay abreast of the latest advancements in the software field. Check out what I've worked on this porfolio!</p>
    </div>
  )
};

export default AboutMe;
