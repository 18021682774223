import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import toast from "react-hot-toast";
function Form() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_txshaph",
        "template_hm8cwle",
        form.current,
        "Ti5r3ZOI2t8PF3awD"
      )
      .then(
        (result) => {
          toast.success("Email was sent!");
        },
        (error) => {
          toast.error("Send a proper message");
        }
      );
  };

  return (
    <div className="mailForm">
      <StyledContactForm>
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" required />
          <label>Email</label>
          <input type="email" name="user_email" required />
          <label>Message</label>
          <textarea name="message" required />
          <input type="submit" value="Send" />
        </form>
      </StyledContactForm>
    </div>
  );
}

const StyledContactForm = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    label {
      margin-top: 1rem;
      color: white;
    }

    input,
    textarea {
      padding: 10px;
      margin-top: 0.5rem;
      border: 1px solid rgb(220, 220, 220);
      border-radius: 5px;
      outline: none;
      font-size: 16px;

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    /* Specific styles for input elements */
    input {
      width: 100%;

      @media (min-width: 768px) {
        width: 80%; /* Width for web (>=768px) */
      }
    }

    /* Specific styles for textarea elements */
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;

      @media (min-width: 768px) {
        max-width: 80%; /* Width for web (>=768px) */
        min-width: 80%;
      }
    }

    input[type="submit"] {
      margin-top: 1.5rem;
      padding: 10px 20px;
      background: rgb(248,217,15);
      color:#222;
      border: 1px solid #fff;
      cursor: pointer;
      font-size: 16px;
      transition: background 0.3s ease;

      &:hover {
        background: rgb(200, 85, 10);
      }
    }
  }
`;
export default Form;