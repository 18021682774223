import NavBar from "../Components/Navbar";
import Footer from "../Components/Footer";
import React from "react";
import PageContent from "../Components/PageContent";
import laptop from '../assets/laptop.png';
import ProjectCards from "../Components/Projects/ProjectCards";

function Projects() {
    return (  <div>
        <NavBar/>
        <PageContent img ={laptop} text1="Projects" text2="Check out my work!" classLabel="ProjectLabel" />
        <ProjectCards/>
        <Footer/>
        </div>);
}

export default Projects;