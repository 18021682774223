import React from "react";
import { NavLink } from "react-router-dom";
import "./ProjectCards.css";
import bank from "../../assets/bank.jpg";
import government from "../../assets/government.jpg";
import recipe from "../../assets/recipe.jpg";
import clothing from "../../assets/clothing.jpg";
import disease from "../../assets/disease.jpg";
import taskmanager from "../../assets/taskmanager.jpg";
import restaurantMap from "../../assets/restaurantMap.jpg";
import dataprocess from "../../assets/dataprocess.jpg";
import ttt from "../../assets/ttt.png";
import doctor from "../../assets/doctor.png";

function ProjectCards() {
  const ProjectData = [

    {
      title: "Task Manager Web App",
      techStack: "React, NodeJS, Express, MongoDB",
      description:
        "Full stack web application that allows users create projects with other users and manage a task board and a dashboard featuring urgent/upcoming tasks among all projects of a user.",
      image: taskmanager,
      view: ["https://task-manager-frontend-p5c4.onrender.com/"],
      source: ["https://github.com/RansikaP/project-manager"],
    },
    {
      title: "Disease Analysis ChatBot",
      techStack: "Python, OpenAI, Scikit Learn, FastAPI, React",
      description:
        "Web app chat bot that leverages OpenAI to process messages sent by user to understand medical symptoms they are experiences and uses an ML model to predict the disease.",
      image: doctor,
      view: ["https://meek-otter-8b544d.netlify.app/"],
      source: ["https://github.com/AkashSivapalan/DiseaseAnalysisBE"],
    },    
    {
      title: "Restaurant Recommendation App",
      techStack: "Spring, Android Studio, MongoDB",
      description:
        "Android app that replicating tinder-like apps to create restauarant recommendations for users to view based on their preferences.",
      image: restaurantMap,
      source: ["https://github.com/AkashSivapalan/RestaurantRecommendationBackend","https://github.com/RansikaP/ResturantReccomendationFrontend"],
    },
    {
      title: "Anime Recommendation Web App",
      techStack: "Python, NumPy, Scikit Learn, FastAPI, React",
      description:
        "Web app that uses an Anime recommendation machine learning model to give recommendations based on a list of Anime",
      image: dataprocess,
      view: ["https://animerecommendationfrontend.onrender.com/"],
      source: ["https://github.com/AkashSivapalan/AnimeRecommendationModel"],
    },
    {
      title: "Tic Tac Toe Game Room Web App",
      techStack: "React, NodeJS",
      description:
        "Web app that uses web sockets to create rooms that users from difference devices can connect allow users from different devices to play tic tac toe and communicate via a chat box.",
      image: ttt,
      view: ["https://dancing-longma-9c3ae2.netlify.app/"],
      source: ["https://github.com/AkashSivapalan/TicTacToeBE","https://github.com/AkashSivapalan/TicTacToeFE"],
    },
    {
      title: "Money Tracker Web App",
      techStack: "React, NodeJS, Express, MongoDB",
      description:
        "Full stack web application that allows users to manage an account with session authentication and record financial transactions that is tracked in a log.",
      image: bank,
      source: ["https://github.com/AkashSivapalan/frontend","https://github.com/AkashSivapalan/api"],
    },
    {
      title: "Find your Representative",
      techStack: "Java, Spring, HTML",
      description:
        "Web application that takes in user’s address and returns the representative for the area.",
      image: government,
      source: ["https://github.com/AkashSivapalan/FindYourRepresentative"],
    },
    {
      title: "Python Recipe Scrapper",
      techStack: "Python, FastAPI",
      description:
        "Web Server application that allows users to create, read, update and delete recipes from the database.",
      image: recipe,
      source: ["https://github.com/AkashSivapalan/FastAPI_RecipeScrapper"],
    },
    {
      title: "Clustering Algorithms from Scratch",
      techStack: "Python, NumPy",
      description:
        "Recreated K-Means, Nearest Neighbor, and DBScan clustering algorithms from scratch. Ran algorithms on 2 datasets; including a 300-sample dataset with 10 features to predict presence of coronary heart disease(chd). Achieved an accuracy of 70%",

      image: disease,
      source: ["https://github.com/AkashSivapalan/Clustering-Algorithms"],
    },

    {
      title: "SQL Clothing Java Query Application",
      techStack: "Java, MySQL",
      description:
        "Program developed in java and connects to a SQLite database and executed SQL commands to query user requests. Designed the database schema and normalized all the tables to 3NF to eliminate redundancies in the database.",

      image: clothing,
      source:
        ["https://github.com/AkashSivapalan/COE848_Lab5_JavaSQL_Application"],
    },
  ];


  const handleClick = (links) => {
    links.forEach((link) => {
      window.open(link, "_blank", "noopener,noreferrer");
    });
  };


  return (
    <div>
      <div className="project-container">
        {ProjectData.map((val, index) => {
          return (
            <div className="project-card">
              <img src={val.image} />
              <h2 className="project-title">{val.title}</h2>
              <h3 className="project-stack">{val.techStack}</h3>
              <div className="pro-details">
                <p>{val.description}</p>
                <div className="details">
                  {val.view && (
                    <button
                      className="details-btn"
                      onClick={() => handleClick(val.view)}>
                      View
                    </button>
                  )}
                  <button                       className="details-btn"
                      onClick={() => handleClick(val.source)}>
Source Code
                  </button>
                  {/* <NavLink
                    to={val.source}
                    className="details-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Source Code
                  </NavLink> */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectCards;
