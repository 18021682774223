import React from "react"

function Work () {
    return (
        <div>
          <h1 className="experience-heading">Work Experience</h1>
          <div className="experience-container">
            
            <div className="experience-card">
              <h2 className="experience-title">QA Analyst @ Equitable Bank</h2>
              <div className="pro-details">
                <p>
                  • Created test cases, and groovy scripts to test functionality of APIs from the online banking applications using SOAPUI and ReadyAPI and maintained 80% test coverage.</p>
                <p>• Developed and ran daily scheduled tests for the backend APIs on multiple testing environments and report bugs/error to DevOps.</p>
                <p>• Developed, executed and reported performance tests using Apache JMeter for API calls.</p>
                <p>• Collaborated with performance team and DevOps to identify and resolve performance issues in test reports. </p>
                <div className="details">
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default Work;
