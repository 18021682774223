import React from "react";
import "./SkillsSection.css";
import checkMarkIcon from "../../assets/checkmark-dark.svg";
import Skill from "./Skill";

function SkillsSection() {
  return (
    <section id="skills" className="skills-container">
      <h1>Programming Skills</h1>
      <div className="skillList">
        <Skill src={checkMarkIcon} skill="Javascript" />
        <Skill src={checkMarkIcon} skill="Node" />
        <Skill src={checkMarkIcon} skill="Express" />
        <Skill src={checkMarkIcon} skill="Java" />
        <Skill src={checkMarkIcon} skill="Spring" />
      </div>
      <hr />
      <div className="skillList">
        <Skill src={checkMarkIcon} skill="React" />
        <Skill src={checkMarkIcon} skill="HTML" />
        <Skill src={checkMarkIcon} skill="CSS" />
        <Skill src={checkMarkIcon} skill="Bootstrap" />
      </div>
      <hr />
      <div className="skillList">
        <Skill src={checkMarkIcon} skill="Python" />
        <Skill src={checkMarkIcon} skill="C" />
        <Skill src={checkMarkIcon} skill="JSON" />
        <Skill src={checkMarkIcon} skill="JUnit" />
        <Skill src={checkMarkIcon} skill="MySQL" />
        <Skill src={checkMarkIcon} skill="NOSQL" />
      </div>

      <hr />
      <h1>Development Tools</h1>
      <div className="skillList">
        <Skill src={checkMarkIcon} skill="Git" />
        <Skill src={checkMarkIcon} skill="SOAPUI / ReadyAPI" />
        <Skill src={checkMarkIcon} skill="ApacheJmeter" />
        <Skill src={checkMarkIcon} skill="Docker" />
        <Skill src={checkMarkIcon} skill="AWS" />
        <Skill src={checkMarkIcon} skill="Azure" />
      </div>
    </section>
  );
}

export default SkillsSection;